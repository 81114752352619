#home {
  padding: 0;
}

.hero-main-cont {
  width: 100%;
  display: flex;
  padding: 1.5rem;
  align-self: start;
  margin-top: 6rem;
  justify-content: center;
  box-shadow: var(--inset-box-shadow);
  border-radius: 20px;
}

.hero-flex {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  max-width: 144rem;
  overflow: hidden;
  gap: 2rem;
  padding: 1rem 2rem;
  padding-bottom: 2rem;
}

.hero-flex > div {
  flex-basis: 50%;
}

.hero-main-text {
  font-size: 4.8rem;
}

.hero-img {
  width: 100%;
}

.hero-sub-cont-1 {
  display: flex;
  flex-direction: column;
  font-weight: 600;
  font-size: 1.8rem;
  gap: 1rem;
  flex: 1;
}

.hero-subtext {
  color: var(--black);
  display: flex;
  gap: 5px;
  font-size: 1.5rem;
}

.what_am_i {
  font-size: 2rem;
}

.head-span-text {
  color: var(--alt-text);
  font-size: 1.3rem;
}

.btn:visited,
.btn:focus {
  box-shadow: var(--inset-box);
}

.btn:visited,
.btn:focus {
  box-shadow: var(--inset-box-dark);
}

.btn-div {
  display: flex;
  gap: 2rem;
  margin-top: 1rem;
}

.btn-div > button {
  padding: 1rem 3rem;
  font-weight: 700;
}

.wave {
  font-size: 4rem;
}

@media (max-width: 62.5em) {
  .hero-main-text {
    font-size: 3rem;
  }
}

@media (max-width: 37.5em) {
  .hero-flex {
    flex-direction: column;
    justify-content: center;
    padding: 4rem 0 0;
    text-align: center;
    gap: 4rem;
  }

  .hero-sub-cont-1 {
    font-size: 1.6rem;
  }

  .hero-center-item {
    margin: auto;
  }

  .wave {
    font-size: 2rem;
  }

  .hero-main-text {
    font-size: 2rem;
  }
  x .what_am_i {
    font-size: 1.5rem;
  }

  .hero-subtext {
    font-size: 1.2rem;
  }
}
