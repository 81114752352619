.about-main-container {
  max-width: 144rem;
}

.about-container {
  display: flex;
  flex-wrap: wrap;
  height: inherit;
  gap: 3rem;
  align-items: center;
  justify-content: center;
  color: var(--black);
}

@media (max-width: 37.5em) {
  .about-container {
    font-size: 1.3rem;
  }
}

#dark .about-container {
  color: var(--white);
}

.about-image {
  width: 100%;
}

.flex {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 1.6rem;
}

.about-header-text-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 1.6rem;
}

.about-header-text-container > P:first-of-type {
  font-weight: 700;
  font-family: 'Montserrat Alternates', sans-serif;
}

.abt-image-div {
  width: 8rem;
  height: 8rem;
}

.about-sub-cont {
  max-width: 60rem;
  display: grid;
  gap: 1rem;
}

.short-hr {
  border: none;
  border-bottom: 5px solid var(--primary-text);
  width: 20%;
  border-radius: 7px;
  margin-bottom: 1rem;
}

#dark .short-hr {
  border-color: var(--yellow);
}

.about-me-text {
  max-width: 60rem;
  text-align: justify;
  text-justify: inter-word;
  margin-bottom: 1rem;
  font-size: 1.6rem;
}

.get-in-touch-btn {
  cursor: pointer;
  animation: buttonPulse 1s infinite;
}
@keyframes buttonPulse {
  50% {
    color: var(--alt-text);
  }
}
@media (max-width: 37.5em) {
  .about-container {
    display: flex;
    flex-direction: column-reverse;
    gap: 4rem;
    width: 90%;
    margin: auto;
  }

  .flex {
    justify-content: center;
  }

  .short-hr {
    width: 10%;
  }
}
