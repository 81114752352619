html {
  box-sizing: border-box;
  /* 1rem = 10px */
  font-size: 62.5%;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body {
  overflow-x: hidden;
}

body #dark,
body #light {
  font-family: 'Montserrat', sans-serif;
  line-height: 1.5;
  color: var(--primary-text);
  background-color: var(--primary-bg-color);
}

.background-image {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 0;
  object-fit: cover;
}

.max-width {
  max-width: 144rem;
  margin: auto;
}

.left_align {
  justify-content: flex-start !important;
}

.scroll_to_top {
  position: fixed;
  bottom: 4rem;
  right: 4rem;
  font-size: 3rem;
  animation: bouncing 2s infinite;
  cursor: pointer;
  height: min-content;
  z-index: 4;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
body::-webkit-scrollbar-track {
  background: var(--white);
}

body::-webkit-scrollbar-thumb {
  height: 3px;
  background: var(--primary-text);
  border-radius: 1rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--yellow);
}

.active {
  box-shadow: var(--inset-box-shadow);
}

.main-container {
  padding-inline: 2rem;
}

footer {
  padding: 1rem;
  padding-bottom: 2rem;
  justify-content: center;
  font-size: 1.6rem;
  text-align: center;
  margin: 0 auto;
}

.footer :nth-child(1) {
  text-transform: capitalize;
}

.footer :nth-child(2) {
  font-weight: 500;
}

.TNP {
  font-weight: 700;
}

#dark .TNP {
  color: var(--yellow);
}

@media (width <=600px) {
  .scroll_to_top {
    font-size: 2rem;
    bottom: 2rem;
    right: 2rem;
  }
}

/* ========== KEYFRAMES ========== */
@keyframes bouncing {
  0% {
    transform: translateY(0);
    filter: grayscale(10%) brightness(40%) sepia(100%) hue-rotate(-50deg)
      saturate(600%) contrast(0.8);
  }

  50% {
    transform: translateY(-1.6rem);
    filter: invert(70%) sepia(65%) saturate(332%) hue-rotate(0deg)
      brightness(104%) contrast(96%);
  }

  100% {
    transform: translateY(0);
    filter: invert(30%) sepia(30%) saturate(2132%) hue-rotate(241deg)
      brightness(96%) contrast(88%);
  }
}

@keyframes bounce {
  0% {
    transform: translateY(1px) translateX(35%);
  }

  50% {
    transform: translateY(-7px) translateX(35%) rotate(10deg);
  }

  100% {
    transform: translateY(0) translateX(35%);
  }
}

@keyframes bounce_main {
  0% {
    transform: translateY(1px);
  }

  50% {
    transform: translateY(-7px) rotate(10deg);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

#shadow-host-companion {
  display: none;
}
