#skill {
  background-image: url(../../../public/assets/skills.png);
  background-repeat: no-repeat;
  background-position-y: 100%;
  background-size: 20%;
}

.my-skills-container {
  width: 70%;
  display: flex;
  gap: 4rem;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: auto;
  margin-bottom: 3rem;
  padding: 0 2rem;
}

@media (max-width: 56.25em) {
  #skill {
    background-size: 50%;
  }

  .my-skills-container {
    width: 100%;
  }
}
