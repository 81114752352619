.section-header-text {
  font-family: 'Montserrat Alternates', sans-serif;
  color: var(--alt-text);
  font-size: 2.4rem;
}

.alt-text {
  color: var(--alt-text);
}

.section-header-flex-container {
  width: max-content;
  margin-inline: auto;
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-bottom: 2rem;
}
