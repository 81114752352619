@import url('https://fonts.googleapis.com/css2?family=Sacramento&display=swap');

header {
  background: var(--primary-bg-color);
  color: var(--primary-text);
  position: sticky;
  top: 0;
  left: 0;
  z-index: 5;
  width: 100vw;
  box-shadow: var(--box-shadow);
}

.overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  background-color: transparent;
  backdrop-filter: blur(10px);
  z-index: 4;
}

.nav-cont {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 1.5rem 2rem;
  max-width: var(--max-width);
  margin: 0 auto;
}

.logo_img {
  width: 5rem !important;
  height: 5rem !important;
  aspect-ratio: 1 !important;
  padding: 0 !important;
  border-radius: 50%;
  z-index: 2;
}

.logo_text {
  padding: 5px 1rem;
  box-shadow: var(--box-shadow);
  z-index: 1;
  border-radius: 5px;
  font-family: 'Sacramento', cursive;
}

.logo {
  font-size: 2rem;
  font-weight: 600;
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
  gap: 1rem;
  position: relative;
  padding: 8px 2rem;
}

.links-container {
  display: flex;
  gap: 1.25rem;
  list-style: none;
}
.links-container > li {
  box-shadow: var(--box-shadow);
  border-radius: 5px;
  transition-property: border, box-shadow;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
}

.active,
.links-container > li:hover,
links-container > li:focus {
  box-shadow: var(--inset-box-shadow);
  border-radius: 1rem 3rem 1rem 3rem;
}

.links {
  padding: 0.5rem 0.8rem;
  font-weight: 400;
  display: flex;
  display: flex;
  align-items: center;
  font-weight: 700;
  gap: 1rem;
  color: inherit;
  font-size: 1.4rem;
}

a {
  color: inherit;
  text-decoration: none;
}

.links:focus,
.links:active {
  box-shadow: var(--inset-box-shadow);
}

.menu-icon {
  display: none;
  cursor: pointer;
}

@media (max-width: 62.5em) {
  .menu-icon {
    display: flex;
    z-index: 10;
    box-shadow: var(--box-shadow);
    padding: 1rem;
    font-size: 15px;
    border-radius: 5px;
    font-weight: 900;
  }

  .menu-icon:hover {
    box-shadow: var(--inset-box-shadow);
  }

  .close-icon {
    display: block;
    margin-left: auto;
    margin-bottom: 3rem;
  }

  .links-container {
    position: fixed;
    display: block;
    align-items: center;
    gap: 3rem;
    top: 0;
    right: 0;
    width: 100%;
    max-width: 30rem;
    height: 100vh;
    background: var(--primary-bg-color);
    opacity: 0;
    transform: translateX(100%);
    transition: transform 0.2s ease-in;
    padding: 1rem 2rem;
    box-shadow: var(--box-shadow);
    overflow: hidden;
  }

  .links-container > li {
    margin-bottom: 2em;
  }

  .show-tab {
    opacity: 1;
    transform: translateX(0);
    z-index: 50 !important;
  }

  .links {
    padding: 1rem;
    justify-content: flex-start;
  }
}

@media (max-width: 37.5em) {
  .nav-cont {
    padding: 0.5rem 0.9rem;
  }

  .logo {
    font-size: 1.5rem;
  }

  .logo_img {
    height: 4rem;
    width: 4rem;
  }

  .links {
    width: 90vw;
    justify-content: flex-start;
    border-radius: 0;
  }
}
