.contact-form-cont {
  background: var(--color-brand-a);
  padding: 1rem 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  color: var(--white);
  border-radius: inherit;
}

#contact {
  padding: 4rem 2rem;
  display: block;
}

.contact_img_div {
  width: 15rem !important;
  height: 15rem !important;
}

.right_hr {
  float: right;
}

.purple {
  box-shadow: var(--purple-box-shadow) !important;
  z-index: 3 !important;
  background: inherit;
  border: none;
  cursor: pointer;
}

.contact-head-text {
  font-size: 4rem;
  text-transform: capitalize;
  z-index: 2;
}

.contact-sub-div {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  position: relative;
  z-index: 3;
}

.contact-sub-div::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0%;
  z-index: 1;
  border-radius: inherit;
  background: url(../../../public/assets/teambuild.png);
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: right bottom;
  opacity: 0.2;
}

.contact-sub-div-2 {
  flex: 1;
}

.my-form {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  padding: 2rem;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  position: relative;
  z-index: 3;
}

.my-form::after {
  content: '';
  position: absolute;
  background: url(../../../public/assets/hero.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  z-index: -1;
  opacity: 0.2;
  padding: 2rem;
}

.form-div {
  display: flex;
  flex-direction: column;
  position: relative;
}

.form-div label {
  position: absolute;
  top: 0px;
  transform: translateY(30%);
  left: 1rem;
  font-size: 1.6rem;
  width: max-content;
  padding: 0.5rem 2.4rem;
  z-index: -1;
  transition: var(--transition);
}

option {
  background: inherit !important;
  -webkit-appearance: none;
  appearance: none;
}

:is(.form-div input:valid, .form-div textarea:valid) {
  background-color: i !important;
  -webkit-appearance: none;
  appearance: none;
}

:is(input:focus, input:valid, textarea:focus, textarea:valid) ~ label {
  transform: translateY(-2.5rem) translateX(-1.5rem);
  display: flex;
  align-items: center;
  background: var(--color-brand-a);
  font-size: 1.2rem;
  z-index: 1;
  -webkit-appearance: none;
  appearance: none;
}

.form-div input,
.form-div textarea {
  border: none;
  outline: none;
  box-shadow: var(--purple-box-shadow);
  padding: 1.5rem;
  border-radius: 5px;
  background: inherit;
  color: var(--primary-bg);
  font-size: 1.3rem;
  font-family: inherit !important;
  font-size: 1.4rem;
  -webkit-appearance: none;
  appearance: none;
  resize: none;
}

.form-div textarea {
  min-height: 10rem;
}

.form-div input:focus,
.form-div input:-webkit-autofill,
.form-div input:-webkit-autofill:focus .form-div textarea:focus,
.form-div input:valid,
.form-div textarea:valid {
  outline: none;
  box-shadow: var(--purple-inset-box);
  background: inherit;
  -webkit-appearance: none;
  appearance: none;
}

.submit-btn {
  width: max-content;
  box-shadow: var(--purple-box-shadow) !important;
  padding: 1rem 3rem !important;
  background: inherit !important;
  color: var(--primary-bg) !important;
  transform: translateY(-2rem);
  display: flex;
  gap: 1rem;
  align-items: center;
  font-size: 1.6rem;
  font-weight: 600;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.submit-btn:hover {
  box-shadow: var(--purple-inset-box) !important;
}

.Message-me {
  text-align: right;
  font-size: 1.8rem;
}

.contact-links {
  background-color: var(--primary-bg) !important;
  box-shadow: var(--purple-box-shadow) !important;
  color: var(--white);
  display: block;
}

.contact-links > svg {
  fill: currentColor;
  width: 2rem;
  height: 2rem;
}

.error_message {
  color: var(--yellow);
  font-style: italic;
  padding: 0.3rem 0;
  animation: pulse 1s infinite;
}

.alt-text-yellow {
  color: var(--yellow);
}

.max_content {
  overflow: hidden;
  box-shadow: var(--box-shadow);
}

#dark .max_content {
  box-shadow: var(--box-shadow-dark);
}

/* ===========THANK YOU PAGE STYLING ========== */
.thank-you {
  background-color: var(--yellow);
  border-radius: inherit;
  padding: 1rem 2rem;
  overflow: hidden;
  position: relative;
  color: #1b60a9 !important;
  flex-wrap: wrap;
}

.thank-you-home {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  flex: 1;
}

.thankyou_img {
  flex: 1;
  z-index: 1;
  animation: bounce_main 3s infinite;
  -webkit-animation: bounce_main 3s infinite;
  -webkit-appearance: none;
  appearance: none;
}

.thankyou_img {
  width: 100%;
  object-fit: contain;
  max-height: 15rem;
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translateX(35%);
  opacity: 0.3;
  animation: bounce 3s infinite;
}

.connect {
  display: flex;
  flex-direction: column;
}

.contact__cont_main {
  width: 80%;
}

.connect-text {
  text-align: left;
  font-size: 1.5rem;
  font-weight: 600;
  z-index: 4;
}

.thank-you-desc {
  z-index: 3;
  font-size: 1.8rem;
}

.center-div {
  display: flex;
  justify-content: center;
}

.thankyou_link {
  box-shadow:
    inset 1px 1px 1rem #f3cc77,
    inset -1px -1px 1rem #cfab5f !important;
}

.thank_you_main {
  width: 100%;
  max-width: 50rem;
}

.xxx {
  box-shadow: var(--purple-box-shadow) !important;
}

/* ----------THANK YOU PAGE STYLING END ========== */
#name {
  text-transform: capitalize;
}

#email {
  text-transform: lowercase;
}

@media (max-width: 56.25em) {
  .contact-form-cont {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 3rem 1rem;
    display: block;
  }

  .contact-sub-div {
    justify-content: center;
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem;
    gap: 2rem;
    text-align: center;
    margin-bottom: 4.8rem;
  }

  .contact-sub-div > button {
    margin: 2rem auto;
  }

  .center-it {
    justify-content: center;
    text-align: center;
  }

  .my-form {
    padding: 1rem;
  }

  .contact-head-text {
    font-size: 3rem;
  }

  .Message-me {
    font-size: 1.4rem;
  }

  .left__align {
    justify-content: flex-start;
  }

  .thank-you-home h1 {
    font-size: 1.8rem;
  }

  .left__align {
    justify-content: flex-start;
  }

  .thank-you-desc,
  .connect-text {
    font-size: 1.3rem;
  }
}

@media (max-width: 37.5em) {
  .Message-me {
    font-size: 1.3rem;
  }

  .form-div input,
  .form-div textarea {
    padding: 1rem;
  }

  .form-div label {
    font-size: 1.3rem;
  }

  .submit-btn {
    padding: 0.5rem 2.5rem !important;
  }

  .contact__cont_main {
    width: 100%;
  }

  #contact {
    padding: 2rem 0;
  }

  .thank-you {
    padding: 1rem;
  }

  .connect-text {
    text-align: center;
  }

  :is(input:focus, input:valid, textarea:focus, textarea:valid) ~ label {
    transform: translateY(-1.3rem) translateX(-1rem);
    font-size: 1rem;
  }
}
