.btn {
  text-decoration: none;
  box-shadow: var(--box-shadow);
  padding: 0.5rem 3rem;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  font-size: 1.6rem;
  font-weight: 600;
  display: flex;
  color: var(--primary-text);
}

.btn > svg {
  width: 2rem;
  height: 2rem;
}

@media (max-width: 37.5em) {
  .btn {
    font-size: 1.3rem;
  }
}

.isBigger {
  font-size: 1.6rem !important;
}

@media (max-width: 37.5em) {
  .isBigger {
    font-size: 1.3rem !important;
  }
}
