#project {
  padding-bottom: 0;
}

.project-container {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin: auto;
  gap: 3rem;
  justify-content: center;
}

.project-desc {
  z-index: 2;
}

.project-hr {
  width: 70%;
}

.project-head-text {
  font-size: 3rem;
}

.pagination-container {
  display: flex;
  justify-content: space-between;
  margin: 2rem auto;
  width: 80%;
  max-width: 40rem;
  padding-block: 5rem;
}

.pagination-btn-text {
  box-shadow: var(--box-shadow);
  background-color: var(--app-bg);
  padding-inline: 2rem;
  position: relative;
  left: -1rem;
  z-index: -1;
}

.pagination-btn-text.next {
  right: -1rem;
  left: auto;
}

.next-prev-btn {
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  font-weight: 700;
  width: max-content;
}

.next-prev-btn:disabled {
  opacity: 0.2;
  cursor: not-allowed;
}

.next-prev-btn > span {
  border-radius: 50%;
  box-shadow: var(--box-shadow);
  padding: 0.5rem;
  width: 3.5rem;
  height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-bg-color);
  text-decoration: none;
  z-index: 1;
}

.next-prev-btn > span > svg {
  fill: currentColor;
  width: 2rem;
  height: 2rem;
}
